import { isEmpty, sortBy } from 'lodash';

import {
	DrivelineViewDto,
	GenerateServiceProtocolData,
	PerformedOperationRequest,
	SaveServiceProtocolCommand,
	SaveServiceProtocolDraftCommand,
	ServiceActivityEventsInfoDto,
	ServiceProtocolStatus,
} from 'api';
import { ServiceProtocolActivitiesQueryParams } from 'api/queries/ServiceProtocolQueries';
import { ParseKeys, TOptions } from 'i18next';
import { fromIsoToDate, fromIsoToLuxonDate } from 'library/formatters';
import { FileUploadState } from 'library/redux/fileUpload';

import {
	ActivitiesType,
	ActivityType,
	ServiceActivityType,
	ServiceInfoStepFormType,
	ServiceInfoStepStateType,
} from '../Types';
import { GenerateServiceProtocolSlice } from './Types';

export const mapFromFormToState = ({
	lastServiceDate,
	productInOperationDate,
	serviceDate,
	...formRest
}: ServiceInfoStepFormType): ServiceInfoStepStateType => ({
	...formRest,
	lastServiceDate: lastServiceDate.toJSON() || '',
	productInOperationDate: productInOperationDate.toJSON() || '',
	serviceDate: serviceDate.toJSON() || '',
});

export const mapFromStateToForm = (
	state: ServiceInfoStepStateType
): ServiceInfoStepFormType =>
	isEmpty(state)
		? ({} as ServiceInfoStepFormType)
		: {
				...state,
				lastServiceDate: fromIsoToLuxonDate(state.lastServiceDate),
				productInOperationDate: fromIsoToLuxonDate(
					state.productInOperationDate
				),
				serviceDate: fromIsoToLuxonDate(state.serviceDate),
			};

export const mapFromStateToQueryParams = (
	{
		language,
		lastServiceDate,
		lastServiceOperatingHours,
		operatingHours,
		productInOperationDate,
		serviceDate,
	}: ServiceInfoStepStateType,
	{ chassisNumber, chassisSeries }: DrivelineViewDto
): ServiceProtocolActivitiesQueryParams => ({
	chassisSeries: chassisSeries || '',
	chassisNumber: chassisNumber || 0,
	currentEngineHours: parseInt(operatingHours || '0'),
	ietfLanguageTag: language,
	productInOperationDate: fromIsoToDate(productInOperationDate),
	productInOperationEngineHours: parseInt(lastServiceOperatingHours || '0'), // TODO: add this conversion to 1st step
	serviceDate: fromIsoToDate(serviceDate),
	lastServiceDate: fromIsoToDate(lastServiceDate),
	lastServiceHours: parseInt(lastServiceOperatingHours || '0'), // TODO: add this conversion to 1st step
});

const mapGenerateServiceProtocolData = (
	serviceInfo: ServiceInfoStepStateType
): GenerateServiceProtocolData => ({
	preferredLanguage: serviceInfo.language,
	serviceDate: new Date(serviceInfo.serviceDate),
	productInOperationDate: new Date(serviceInfo.productInOperationDate),
	currentEngineHours:
		serviceInfo.operatingHours != null
			? parseInt(serviceInfo.operatingHours)
			: serviceInfo.operatingHours ?? null, // TODO: add this conversion to 1st step
	lastServiceEngineHours:
		serviceInfo.lastServiceOperatingHours != null
			? parseInt(serviceInfo.lastServiceOperatingHours)
			: serviceInfo.lastServiceOperatingHours ?? null, // TODO: add this conversion to 1st step
	lastServiceDate: new Date(serviceInfo.lastServiceDate),
});

const mapServiceActivities = (
	data: ActivitiesType
): PerformedOperationRequest[] => {
	const serviceActivityKeys = Object.keys(data.activities);
	const performedOperations: SaveServiceProtocolDraftCommand['performedOperations'] =
		[];

	for (const activityId of serviceActivityKeys) {
		const serviceActivityId = parseInt(activityId);
		const activityServices = data.activities[serviceActivityId].services;
		const servicesKeys = Object.keys(
			data.activities[serviceActivityId].services
		);

		for (const servicesKey of servicesKeys) {
			const operationId = parseInt(servicesKey);

			const operation = activityServices[operationId];

			performedOperations.push({
				serviceActivityId: serviceActivityId,
				operationId: operationId,
				comment: operation.comment ?? null,
				isPerformed: operation.isPerformed ?? null,
				isProposal: operation.isProposal ?? null,
				photo: null, // read from url?
			});
		}
	}
	return performedOperations;
};

export const mapFromStateToRequest = async (
	data: GenerateServiceProtocolSlice,
	fileState: FileUploadState
): Promise<SaveServiceProtocolCommand> => ({
	drivelineId: data.drivelineId,
	serviceProtocolId: data.serviceProtocolId,
	generateServiceProtocolData: mapGenerateServiceProtocolData(
		data.serviceInfo
	),
	location: data.location,
	comment: data.offlineActivities.additionalComment,
	customProtocolName: data.offlineActivities.serviceName,
	folderName: fileState.attachmentFolderName,
	offlineProtocolFileName:
		fileState.filesUploaded.find(({ isAdditional }) => !isAdditional)
			?.serverName || '',
	uploadedFiles: fileState.filesUploaded
		.filter(({ isAdditional }) => isAdditional)
		.map(({ serverName }) => serverName),
});

export const mapFromStateToDraftRequest = async (
	data: GenerateServiceProtocolSlice,
	fileState: FileUploadState
): Promise<SaveServiceProtocolDraftCommand> => ({
	drivelineId: data.drivelineId,
	id: data.serviceProtocolId,
	generateServiceProtocolData: mapGenerateServiceProtocolData(
		data.serviceInfo
	),
	performedOperations: mapServiceActivities(data.activities),
	usedNumberOfHours: parseInt(data.activities.usedHours),
	alreadyUploadedFilesToBeDeleted: [], // todo - add any deleted already uploaded files here
	location: data.location,
	comment: data.offlineActivities.additionalComment,
	attachmentFolderName: fileState.attachmentFolderName,
	uploadedFiles: fileState.filesUploaded
		.filter(({ isAdditional }) => isAdditional)
		.map(({ serverName }) => serverName),
});

export const mapQueryResponseToType = (
	dto?: ServiceActivityEventsInfoDto
): ActivityType[] => {
	if (!dto) {
		return [];
	}

	return dto.serviceActivityEvents.map(
		({
			description,
			engineHours,
			estimatedHours,
			isPeriodic,
			months,
			operations,
			recommended,
			serviceActivityId,
			sortOrder,
		}) => ({
			estimation: estimatedHours,
			header: {
				description: description || '',
				hours: engineHours || 0,
				isPeriodic: isPeriodic || false,
				months,
			},
			services: sortBy(
				operations.map(
					({
						description,
						isEmissionRelated,
						lastPerformed,
						operationId,
						sortOrder,
						task: { type },
					}) => ({
						action: type,
						id: operationId,
						isEmissionRelated,
						lastServiceDate: lastPerformed,
						name: description,
						sortOrder,
					})
				) as ServiceActivityType[],
				({ sortOrder }) => sortOrder
			),
			id: serviceActivityId,
			isRecommended: recommended,
			sortOrder,
		})
	);
};
export const getServiceProtocolStatusToTranslationMapping = (
	status: ServiceProtocolStatus | null
): ParseKeys<['serviceProtocol'], TOptions, 'serviceProtocol'> => {
	switch (status) {
		case 'Initiated':
			return 'serviceProtocol:status-initiated';
		case 'Finalized':
			return 'serviceProtocol:status-finalized';
		default:
			return 'serviceProtocol:status-not-saved';
	}
};
